<template>
  <div>
    <div style="display: inline-block; width: 40px;">
      <div style="padding: 10px; background-color: #d3d3d3; text-align: center;">This is a very wide filter</div>
      <CButton class="mb-2" @click="updateFilter(1)" color="success">1</CButton>
      <CButton class="mb-2" @click="updateFilter(2)" color="success">2</CButton>
      <CButton class="mb-2" @click="updateFilter(null)" color="success">NULL</CButton>
    </div>
  </div>
</template>


<script>


export default {  
  
  data: function () {
    return {
      jml: null,
    };
  },
  methods: {
    updateFilter(jml) {
      this.jml = jml
      this.params.filterChangedCallback();
      //console.log(this.data)
    },

    doesFilterPass(params) {
      //return params.data.year >= 2010;
    },

    isFilterActive() {
      return this.jml != null;
    },

    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {
      if(this.jml!=null){
        return { 
          filter: this.jml,
          filterType : 'number',
          type:'equals'
        };
      } return null
    },

    setModel(model) {
      console.log(model);
      //this.eFilterText.value = model.value;
    },
  },
};
</script>
