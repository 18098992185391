<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            Photos List
            <div class="card-header-actions">
              <a href="https://coreui.io/vue/docs/components/card-components" class="card-header-action"
                rel="noreferrer noopener" target="_blank">
                <small class="text-muted">docs</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody>
            <CButton @click="refresh()" color="primary">Refresh</CButton>
            <CButton @click="getSelectedRows()" color="primary">Row Selection</CButton>
            <br>
            <ag-grid-vue 
              style="height: 500px;" 
              class="ag-theme-material" 
              id="myGrid" 
              :columnDefs="columnDefs"
              @gridReady="onGridReady" 
              @cellDoubleClicked="oncellDoubleClicked" 
              :defaultColDef="defaultColDef"
              :pagination="true" 
              :masterDetail="true" 
              :rowClassRules="rowClassRules" 
              detailCellRenderer="Detail"
              paginationPageSize="100" 
              rowModelType="serverSide" 
              serverSideStoreType='partial' 
              rowSelection="multiple"
              :context="{componentParent: this}"
            >
            </ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
  import {
    AgGridVue
  } from "ag-grid-vue";
  import "ag-grid-enterprise";

  import Detail from './Detail.vue';
  import CellRender from './CellRender.vue';
  import Filter from './Filter.vue';
  import Vue from 'vue';

  import 'ag-grid-community/dist/styles/ag-grid.css';
  import 'ag-grid-community/dist/styles/ag-theme-material.css';

  const childMessageRenderer = Vue.component('buttons', {
    template: `<div
                <CButton class="mb-1" size="sm" @click="showModal" color="success"><CIcon name="cil-plus"/></CButton>
              </div>`,
      methods: {
        hide(){
          
        },
        showModal(){
          console.log(this.params.node.data);
          this.params.context.componentParent.cekChildParent(`Row: ${this.params.node}, Col: ${this.params.colDef.headerName}`)
        }
      }
    }
  );

  export default {
    name: 'Photos',
    components: {
      AgGridVue,
      Detail,
      Filter,
      childMessageRenderer
    },
    data() {
      return {
        columnDefs: [
          {
            headerName: '#',
            width: 50,
            cellRenderer: function (params) {
              return parseInt(params.node.id) + 1;
            },
          },
          {
            field: 'athlete',
            minWidth: 220,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              checkbox: true
            }
          },
          {
            field: 'country.name',
            minWidth: 200,
            filter: 'agSetColumnFilter',
            menuTabs: ['filterMenuTab'],
            filterParams: {
              values: ['Australia', 'China', 'Sweden']
            },
          },
          {
            field: 'year',
            filter: 'agNumberColumnFilter',
            cellRenderer: function (params) {
              return parseInt(params.data.date).toLocaleString();
            },
          },
          {
            field: 'date',
            filter: 'agDateColumnFilter',
          },
          //{ field: 'sport', filter:'agTextColumnFilter' },
          {
            field: 'sport.name',
            cellRendererFramework: CellRender
          },
          {
            field: 'gold',
            cellStyle: params => {
              if (params.value >= 2) {
                return {
                  color: 'red',
                  backgroundColor: '#f2f238'
                };
              }
              return null;
            }
          },
          {
            field: 'silver',
            filter: 'agSetColumnFilter',
            filterParams: {
              excelMode: 'windows'
            },
          },
          {
            field: 'bronze',
            filterFramework: 'Filter'
          },
          {
            headerName: 'action',
            width: 50,
            cellRendererFramework: "childMessageRenderer",
            colId: "params",
          },
        ],
        gridApi: null,
        columnApi: null,
        defaultColDef: {
          //flex: 1,
          minWidth: 20,
          sortable: true,
          serverSideStoreType: 'partial',
          animateRows: true,
        },
        rowClassRules: {
          'lebih-dari4': 'data.country == "Indonesia"',
          'lebih-dari1': 'data.country == "India"',
          // 'warning': true,
          // 'danger': function(params) { return params.data.year === 2004; },
          // 'rag-amber-outer': function(params) { return params.data.year === 2004; },
        },
        getRows2: {
          async getRows(params) {
            console.log(JSON.stringify(params.request, null, 1));

            /*

            fetch('https://www.ag-grid.com/example-assets/olympic-winners.json', {
                method: 'get',
                //body: JSON.stringify(params.request),
                //headers: {"Content-Type": "application/json; charset=utf-8"}
            })
            .then(httpResponse => httpResponse.json())
            .then(response => {
              //console.log(response);
                params.successCallback(response, -1);
            })
            .catch(error => {
                console.error(error);
                params.failCallback();
            })
            */
          }
        },
      }
    },
    beforeMount() {
      //this.rowModelType = 'serverSide';

    },
    methods: {
      refresh() {
        this.gridApi.refreshServerSideStore({
          purge: true,
        });
      },
      oncellDoubleClicked(params) {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        this.$swal('Good job!', `Selected nodes: ${JSON.stringify(selectedData, 2, 2)}`, 'success');
      },
      onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        params.api.setServerSideDatasource({
          getRows: async (p) => {
            console.log(JSON.stringify(p.request, null, 1));
            try {
              const {
                data
              } = await this.$http.post('winners/all', p.request)
              p.successCallback(data.data.rows, data.data.lastRow);
            } catch (err) {
              this.$toast.error(err.message);
              p.failCallback()
            }

          }
        });

      },
      getSelectedRows() {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        //const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
        this.$swal('Good job!', `Selected nodes: ${JSON.stringify(selectedData, 2, 2)}`, 'success');
      },
      agOnSort(params) {
        console.log(params.columnApi.columnModel.gridColumns);
      },
      cekChildParent(text){
        this.$swal('Good job!', text, 'success');
      },
      getrow2() {
        return {
          getRows: async (p) => {
            console.log(JSON.stringify(p.request, null, 1));

            const {
              data
            } = await this.$http.post('winners/all', p.request)

            p.successCallback(data.data.rows, data.data.lastRow);
          }
        }
      }
    },
  }

  window.createServerSideDatasource = function createServerSideDatasource(
    server
  ) {
    return {
      getRows: function (params) {
        //console.log('[Datasource] - rows requested by grid: ', params.request);
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows
            });
          } else {
            params.fail();
          }
        }, 500);
      },
    };
  };

  window.createFakeServer = function createFakeServer(allData) {
    return {
      getData: function (request) {
        var requestedRows = allData.slice();
        return {
          success: true,
          rows: requestedRows,
        };
      },
    };
  };
</script>

<style lang="css">
  .lebih-dari4 {
    background-color: sandybrown !important;
  }

  .lebih-dari1 {
    background-color: lightcoral !important;
  }
</style>