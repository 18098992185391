<template>
  <div>
    <CButton class="mb-2" @click="hello" color="success">{{ printVal() }}</CButton>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  methods: {
    printVal() {
      //console.log(this.params.value);
      return this.params.value;
    },
    hello() {
      alert(this.params.data.athlete)
    }
  }
});
</script>
